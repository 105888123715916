import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Dashboard/Dashboard.css";
import anim from "../../Assets/images/anim (2).gif";
import { BaseUrl } from '../../Url';
import { BsBoxArrowRight } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import Meter from '../User/Meter';



function Dashboard() {
  const formatDateTime = (dateTimeString) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      // hour: '2-digit',
      // minute: '2-digit',
    };
  
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString('en-GB', options);
    const formattedTime = date.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
  
    return `${formattedDate}, ${formattedTime}`;
  };
  
  
  const [data, setData] = useState([]);
  const [showFinishConfirmation, setShowFinishConfirmation] = useState(false);
  const [selectedLRNO, setSelectedLRNO] = useState('');
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [View, setView] = useState(false)
  const [meterData, setMeterData] = useState([]);
  const [Ddata, setDdata] = useState([])
  const [ViewLRNO,setViewLRNO] = useState('')
  const [Mdata, setMdata] = useState('')
  const [showMeterModal, setShowMeterModal] = useState(false);


  const navigate = useNavigate();
console.log(Mdata,"mdata ");

  const parms = useLocation();



   const handleHistory=()=>{
    navigate("/History")
   }
  

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    localStorage.removeItem('authData');
    setShowLogoutModal(false);
    navigate('/admin');
  };

  const handleFinishConfirmation = (lrno) => {
    setSelectedLRNO(lrno);
    setShowFinishConfirmation(true);
  };

  const handleCloseFinishConfirmation = () => {
    setShowFinishConfirmation(false);
  };
  const handleCloseMeterModal = () => {
    setShowMeterModal(false);
  };
const  handleShowMeterModal =async (LRNO)=>{
  setShowMeterModal(true);

  // console.log("jee",updateData);
  // console.log("FF",lrno);
 
  setView(true);
 setViewLRNO(LRNO)
 console.log(ViewLRNO,"viewlrno");
//  console.log("bb",ViewLRNO);
//  axios.post(BaseUrl+'/api/view', ViewLRNO ).then(function(response){
  // console.log("check",ViewLRNO);
  // console.log(response.data);
  try {
    const response = await axios.get(BaseUrl + "/api/view", { params: { LRNO } });
    // setData(response.data);
    setMdata(response.data)
    console.log(response.data,"responselrno");
    console.log("lrno",LRNO);
} catch (error) {
    console.error(error);
    if (error.response && error.response.status === 400) {
        // Show a toast if the status code is 400
        // setShow(false)
        // toast.error('Please enter a valid LRNO', {
        //     position: toast.POSITION.TOP_CENTER,
        //     autoClose: 3000,
        // });
    }
}



//  })



}



  const handleConfirmFinish = () => {
    axios.post(BaseUrl + `/finish?lrno=${selectedLRNO}`).then(function (response) {
      RefreshDate();
      // console.log(response.data);
    });
    handleCloseFinishConfirmation();
  };

  const handelHistory = () => {
    navigate("/History");
  };

  useEffect(() => {
    const authData = localStorage.getItem('authData');
    if (!authData) {
      navigate('/');
    }
  }, [navigate]);

  const handleButton = () => {
    navigate("/add");
  };

  const RefreshDate = () => {
    try {
      axios.get(BaseUrl + '/getvehicledata')
        .then(response => {
          // console.log("Vehicle Data response:", response);
          setData(response.data);
        })
        .catch(error => {
          console.error('Error fetching vehicle data:', error);
        })
    } catch (error) {

    }
  }
  // console.log("THE DATA IS" , data);

  useEffect(() => {
    axios.get(BaseUrl + '/getvehicledata')
      .then(response => {
        // console.log("Vehicle7 Data response:", response);
        setData(response.data);

      })
      .catch(error => {
        console.error('Error fetching vehicle data:', error);
      });
  }, []);


  // const [updateData, setUpdateData] = useState([]);
  // console.log("up",updateData);


  // useEffect(() => {
  //   axios.get(BaseUrl + '/getupdatevehicledata')
  //     .then(response => {
  //       console.log("Update Data response:", response);
  //       setUpdateData(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching update data:', error);
  //     });
  // }, []);

//   const mergedData = data.map((vehicle, index) => {
//     const update = updateData.find((updateItem) => updateItem.LR_no === vehicle.LR_no) || {};
//     return {
//       ...vehicle,
//       Location: update.location || '',
//       Time: update.time || '',
//       Date: update.Date || '',
//       CurrentStatus: update.current_status || '',
//     };
//   });
//   // setDdata(mergedData)
// console.log("m",mergedData);
  return (
    <div className="container">
      <div className="d-flex flex-column align-items-center vh-100">
        {Array.isArray(data) && data.length === 0 && (
          <div className="text-center">
            <div className='noData'><img src={anim} alt="" className='img-fluid imgg' /></div>
            <p className="mb-3">No vehicle has gone for delivery</p>
            <button className="btn btn-success" onClick={handleButton}>
              Add
            </button>
            <button className="btn btn-success" style={{marginLeft:"15px"}} onClick={handleHistory}>
              History
            </button>
          </div>
        )}
        {Array.isArray(data) && data.length > 0 && (
          <div>
            <h1>Currently Running Loads</h1>
            <BsBoxArrowRight className='logout' onClick={handleLogout} style={{ fontSize: '36px', color: '#184A45FF', cursor: 'pointer' }} />
            <table className="table table-hover custom-table-new">
              <thead>
                <tr>
                  <th scope="col">Sl no</th>
                  <th scope="col">LRNO</th>
                  <th scope="col">Driver Name</th>
                  <th scope="col">Driver Number</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Vehicle Number</th>
                 
                  <th scope="col">Location</th>
                  <th scope="col">Time Date</th>
                 


                  <th scope="col">Current Status</th>
                  <th scope="col">Remark</th>
                  <th colSpan="4">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.LR_no}</td>
                    <td>{item.Driver_name}</td>
                    <td>{item.Driver_number}</td>
                    <td>{item.Customer_name}</td>
                    <td>{item.Vehicle_number}</td>
                   
                    <td>{item.location}</td>
                    <td>{formatDateTime(item.Date)}</td>
                    


                    
                    {/* <td className='custom-date-column'>{item.Date}</td> */}
                    <td style={{ color: item.CurrentStatus === "Finished" ? "green" : "", fontWeight: item.CurrentStatus === "Finished" ? "bold" : "normal" }}>
                      {item.current_status}
                    </td>
                    <td>{item.Remarks}</td>
                    <td>
                      <button className="btn btn-primary history" onClick={() => { navigate("/add", { state: { data: item } }) }}>Edit</button>
                    </td>
                    <td>
                      {/* <button className="btn btn-secondary history" onClick={() => { navigate("/user", { state: { data: item } }) }}>View</button> */}
                      <button className="btn btn-secondary history" onClick={()=>handleShowMeterModal(item.LR_no)}>View</button>

                    </td>
                    <td>
                      <button className="btn btn-warning history" onClick={() => { navigate("/update", { state: { data: item } }) }}>Update</button>
                    </td>
                    <td>
                      <button className="btn btn-danger history" onClick={() => handleFinishConfirmation(item.LR_no)}>Finish</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button className="btn btn-success history" onClick={handleButton}>
              Add
            </button>
            <button className="btn btn-success history" onClick={handelHistory}>
              History
            </button>
          </div>
        )}
<Modal show={showMeterModal} onHide={handleCloseMeterModal} centered>
        <Modal.Header closeButton>
          <Modal.Title> Location of vehicle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {View ? <Meter Data={Mdata} /> : ""}
        </Modal.Body>
      </Modal>      </div>

      {/* Finish Confirmation Modal */}
      <Modal show={showFinishConfirmation} onHide={handleCloseFinishConfirmation} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to finish LRNO {selectedLRNO}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleConfirmFinish}>
            Finish
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Logout Confirmation Modal */}
      <Modal show={showLogoutModal} onHide={() => setShowLogoutModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Logout Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to logout?
        </Modal.Body>
        <Modal.Footer>
          <Button className='logout' variant="primary" onClick={confirmLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Dashboard;

