import React, { useEffect} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Admin from "./components/Admin/Admin"
import Dashboard from './components/Dashboard/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import User from './components/User/User';
import Add from './components/AddVehicle/Add';
import Update from './components/Admin/Update';
import History from './components/Admin/History';
import { appVersion } from './Url';
import { BaseUrl } from './Url';
import axios from 'axios';



const App = () => {

useEffect(() => {
  axios.get(BaseUrl + '/latestVersion')
  .then((response)=>{
    if(response.status!==200){
      throw new Error('Received non-200 status code');
    }
    return response.data;
  }).then((data)=>{
    const latestVersion=data.latestVersion
if(appVersion!==latestVersion){
      window.location.reload(true);
      console.log("check");
    }
  })
     .catch((error)=>{
      console.log('error checking latest version',error);
     })
 
}, [])


  return (
    <Router>
      <Routes>
        <Route path="/" element={<User />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/admin" element={<Admin/>} />
        <Route path="/add" element={<Add/>} />
        <Route path="/update" element={<Update/>} />
        <Route path="/History" element={<History/>} />


      </Routes>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

export default App;
