import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import '../Admin/update.css'
import { TbArrowBackUpDouble } from 'react-icons/tb';
import DatePicker from "react-datepicker";


import { useLocation, useNavigate } from 'react-router-dom';
import { BaseUrl } from '../../Url';
import DateTimePicker from 'react-datetime-picker';
import { param } from 'jquery';
// import {HandelLRNO} from "../User/User"

const Update = () => {


  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    // Adjust the format to comply with "yyyy-MM-ddThh:mm"
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  
  
  const [UpdateValue, setUpdateValue] = useState('');

  const [statusValue, setstatusValue] = useState([])
  // const [Time, setTime] = useState('');
  const [TimeDate, setTimeDate] = useState('')
  const [LRNO, setLRNO] = useState('');
  const [Location, setLocation] = useState('');
  const [CurrentStatus, setCurrentStatus] = useState('');
  const parms = useLocation();
  const navigate = useNavigate();
  const [Statuss, setStatuss] = useState([]);
// console.log("shanubabu date", formatDateTime(TimeDate));

  useEffect(() => {
    axios.get(BaseUrl + "/currentStatus")
      .then(function(response) {
        // console.log("statuss", response.data);
        setStatuss(response.data.data); // Extract the 'data' property
      })
      .catch(function(error) {
        console.log(error);
      });
  }, []);


  const handleBack = () => {
    navigate("/dashboard");
  };

useEffect(() => {
  if(parms.state){
    setstatusValue(parms.state.statusData)
  }
}, [])
// console.log("parms.state.statusData",parms.state.statusData);

  useEffect(() => {
    if (parms.state) {
      setUpdateValue(parms.state.data);
  
    }
  }, []);



  useEffect(() => {
    if (UpdateValue) {
      // console.log(UpdateValue)
      setLocation(UpdateValue.location);
      // setTime(UpdateValue.Time);
      setCurrentStatus(UpdateValue.current_status);
      setLRNO(UpdateValue.LR_no);
      setTimeDate(UpdateValue.Date)
    }
  }, [UpdateValue]);
  // console.log("updateValue" , UpdateValue);


 
  
  





  const HandelUpdate = (e) => {
    e.preventDefault();

    if (
      Location.trim() === '' ||
      TimeDate.trim() === '' ||
      CurrentStatus.trim() === ''
      
    ) {
      toast.error('Please fill out all required fields', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeButton: false,
      });
      return;
    }

    const Data = {
      LRNO,
      TimeDate,
      Location,
      CurrentStatus,
    };

    axios.post(BaseUrl+"/api/updateData", Data)
      .then(function(response){
        // console.log("Data sented:", response.data);
        toast.success(' successfully!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
          closeButton: false,
        });
        // HandelLRNO()
        navigate('/dashboard');
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const statusOptions = [
    "Started",
    "On_Travel",
    "One_Completed",
    "Two_Completed",
    "Three_Completed",
    "Four_Completed",
    "Five_Completed",
    "Finished"
  ];
  function formatDate(inputDate) {
    // console.log(inputDate)
    const [year, month, day] = inputDate.split("-");
    // Format the date as "YYYY-MM-DD"
    return `${year}-${month}-${day}`;
  }
  return (
    <div className="container mt-5">
      <div className="card shadow">
        <div className="card-body">
          <span className='back'>
        <TbArrowBackUpDouble  onClick={handleBack} style={{ fontSize: '36px', color: '#184A45FF',cursor: 'pointer' }} />
          <h2 className="mb-4 text-center text-primary update">Update Data</h2></span>
          
          <form>
            {/* <div className="mb-3">
              <label htmlFor="time" className="form-label">
                Time:
              </label>
              <input type="text" className="form-control" id="time" onChange={(e) => setTime(e.target.value)} value={Time} name='time' />
            </div> */}
           <div className="mb-3 myFormContainer">
  <label htmlFor="time" className="form-label">
    Date:
  </label>
  <input
    type="datetime-local"
    value={formatDateTime(TimeDate)}
    onChange={(e) => setTimeDate(e.target.value)}
    className="myInput"
  />
</div>
{/* <div className="mb-3">
          <label htmlFor="currentStatus" className="form-label">
            Current Status
          </label>
          <select
  className="form-select"
  id="currentStatus"
  value={CurrentStatus}
  onChange={(e) => setCurrentStatus(e.target.value)}
  name="CurrentStatus"
  required
>
  <option value="" disabled>
    Select Current Status
  </option>
  {Array.isArray(Statuss) && Statuss.map((status) => (
  <option key={status.id} value={status.current_status}>
    {status.current_status}
  </option>
))}
</select>

        </div> */}
            <div className="mb-3">
              <label htmlFor="location" className="form-label">
                Location:
              </label>
              <input type="text" className="form-control" id="location" name='Location' onChange={(e) => setLocation(e.target.value)} value={Location} />
              {/* <input type="text" className="form-control" id="location" name='Location' value={formatDate(TimeDate)} onChange={(e)=>setTimeDate(e.target.value)}/> */}

            </div>
            <div className="mb-3">
          <label htmlFor="currentStatus" className="form-label">
            Current Status
          </label>
          <select
  className="form-select"
  id="currentStatus"
  value={CurrentStatus}
  onChange={(e) => setCurrentStatus(e.target.value)}
  name="CurrentStatus"
  required
>
  <option value="" disabled>
    Select Current Status
  </option>
  {Array.isArray(Statuss) && Statuss.map((status) => (
  <option key={status.id} value={status.current_status}>
    {status.current_status}
  </option>
))}
</select>

        </div>
            <div className="d-grid">
              <button type="button" className="btn btn-primary btn-block mt-4" onClick={HandelUpdate}>
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Update;
