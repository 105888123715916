import React, { useState, useEffect, useRef } from 'react';
import '../User/Meter.css';
import moment from 'moment-timezone';

const Meter = (props) => {
  const [completed, setCompleted] = useState(false);
  const [currentHole, setCurrentHole] = useState(null);
  const [scrollContainer, setScrollContainer] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!completed) {
        setCurrentHole((prevHole) => (prevHole === null ? 0 : prevHole + 1));
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [completed]);

  useEffect(() => {
    if (scrollContainer) {
      // Make the scrollbar always appear at the bottom
      const { scrollHeight, clientHeight } = scrollContainer;
      scrollContainer.scrollTop = scrollHeight - clientHeight;
    }
  }, [completed, scrollContainer]);

  const handleFinish = () => {
    if (!completed) {
      setCompleted(true);
      setCurrentHole(null);
    }
  };

  function formatDateTime(dateTimeString) {
    if (!dateTimeString) {
      return ''; // Handle the case where dateTimeString is undefined or null
    }
  
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'Asia/Kolkata', // India Standard Time (IST)
    };
  
    try {
      // Parse the input date string
      const dateObject = new Date(dateTimeString);
  
      // Adjust to Indian Standard Time (IST) offset (UTC+5:30)
      dateObject.setMinutes(dateObject.getMinutes() );
  
      // Add 1 hour
      dateObject.setHours(dateObject.getHours() );
  
      // Format the date using the options
      const formattedDate = dateObject.toLocaleString('en-US', options);
  
      return formattedDate;
    } catch (error) {
      console.error('Error formatting date:', error);
      return ''; // Return an empty string if there's an error
    }
  }
  
  
  
  const renderHoles = () => {
    if (!Array.isArray(props.Data)) {
      return <div>No data available</div>;
    }

    const circleHeight = 18;
    const spacing = 32;
    const numberOfHoles = props.Data.length;

    const lineHeight = numberOfHoles * (circleHeight + spacing) - spacing;
    // console.log(props.Data,"mamaldata");

    return (
      <div className="hole-container">
      
        {props.Data.map((dataItem, index) => (
          <div key={index} className={`hole ${index <= currentHole ? 'active' : ''}`}>
            {index <= currentHole && (
              <span className="left-text">{dataItem.location}@{formatDateTime(dataItem.Date)}</span>
            )}
            {index <= currentHole && (
              <div className={`right-text ${dataItem.current_status === 'Finished' ? 'textt' : ''}`}>
                {dataItem.current_status}
              </div>
            )}
          </div>
        ))}
        <div className="vertical-line" style={{ height: `${lineHeight}px` }}></div>
      </div>
    );
  };

  return (
    <div className="App">
      <div
        className="scroll-container"
        ref={(ref) => setScrollContainer(ref)}
      >
        {renderHoles()}
        {completed && <p className="completion-message">All holes colored!</p>}
      </div>
    </div>
  );
};

export default Meter;
