import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../AddVehicle/Add.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { BaseUrl } from '../../Url';
import { TbArrowBackUpDouble } from 'react-icons/tb';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';


import "react-datepicker/dist/react-datepicker.css";
// import { format } from 'date-fns/esm';
import { set } from 'date-fns';




function Add() {
  const [LRNO, setLRNO] = useState('');
  const [DriverName, setDriverName] = useState('');
  const [DriverNo, setDriverNo] = useState('');
  const [CustomerName, setCustomerName] = useState('');
  const [CustomerNumber, setCustomerNumber] = useState('');
  const [Remarks, setRemarks] = useState('');
  const [Location, setLocation] = useState('');
  const [TimeDate, setTimeDate] = useState('');
  const [Date, setDate] = useState('');
  const [Vehicle, setVehicle] = useState('');
  const [CurrentStatus, setCurrentStatus] = useState('');
  const [EditValue, setEditValue] = useState('');
  const [Id, setId] = useState('')
  // const [selectedDate, setSelectedDate] = useState(null);

  const [Statuss, setStatuss] = useState([]);


  useEffect(() => {
    axios.get(BaseUrl + "/currentStatus")
      .then(function(response) {
        // console.log("statuss", response.data);
        setStatuss(response.data.data); // Extract the 'data' property
      })
      .catch(function(error) {
        console.log(error);
      });
  }, []);
  
 
  const formatDateTime = (dateTimeString) => {
    if(dateTimeString){
      // console.log(dateTimeString)
      const date = new Date(dateTimeString);
 
      if (isNaN(date)) {
        // console.error("Invalid date string:", dateTimeString);
      } else {
        // console.log(date)
        const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
    
      // Adjust the format to comply with "yyyy-MM-ddThh:mm"
      return `${year}-${month}-${day}T${hours}:${minutes}`;
      }
      
    }
   
  };



  function formatDate(inputDate) {
    // console.log(inputDate)
    if (!inputDate) {
      return ''; // or handle the case where inputDate is undefined or null
    }
  
    const dateParts = inputDate.split('T');
    const date = dateParts[0];
    const time = dateParts[1].slice(0, 5); // Keep only the first 5 characters for 'hh:mm'
    
    return `${date}T${time}`;
  }
  

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  // const formattedDate = selectedDate ? format(selectedDate, 'dd-MM-yyyy') : '';

  const parms = useLocation();


  useEffect(() => {
    axios.get(BaseUrl + '/getvehicledata').then(function (response) {
      setVehicle(response.data);
    });
  }, []);
  // console.log("vehicle:",Vehicle);

  useEffect(() => {
    if (parms.state) {
      setEditValue(parms.state.data);
    }
  }, []);
  // console.log("editValue",EditValue);

  useEffect(() => {
    if (EditValue) {
      setDriverName(EditValue.Driver_name);
      setLRNO(EditValue.LR_no);
      setDriverNo(EditValue.Driver_number);
      setCustomerName(EditValue.Customer_name);
      setCustomerNumber(EditValue.Vehicle_number); 
      setRemarks(EditValue.Remarks);
      setLocation(EditValue.location);
      setTimeDate(EditValue.Date);
      // setDate(EditValue.Date)
      setCurrentStatus(EditValue.current_status);
      setId(EditValue.id)
    }
  }, [EditValue]);

  // const statusOptions = [
  //   'Started',
  //   'On_Travel',
  //   'One_Completed',
  //   'Two_Completed',
  //   'Three_Completed',
  //   'Four_Completed',
  //   'Five_Completed',
  //   'Finished',
  // ];

  const navigate = useNavigate();


  const HandelUpdate = (e) => {
    e.preventDefault();

    if (
      LRNO.trim() === '' ||
      DriverName.trim() === '' ||
      String(DriverNo).trim() === '' ||
      CustomerName.trim() === '' ||
      String(CustomerNumber).trim() === '' ||

      
      Remarks.trim() === '' ||
      Location.trim() === '' ||
      TimeDate.trim() === '' ||
      // Date.trim() === '' ||
      CurrentStatus.trim() === ''
    ) {
      toast.error('Please fill out all required fields', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeButton: false,
      });
      return;
    }

    const Data = {
      LRNO,
      DriverName,
      DriverNo,
      CustomerName,
      CustomerNumber,
      Remarks,
      Location,
      TimeDate,  
      CurrentStatus,
      Id
    };
  

    axios
      .post(BaseUrl + '/api/EditData', Data)
      .then(function (response) {
        console.log('updateEdit:', response.data);
        console.log('updateEdit2:',Data);

        toast.success('Updated Successfully!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
          closeButton: false,
        });

        navigate('/dashboard');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const  HandelSubmit = (e) => {
    e.preventDefault();

    if (
      LRNO.trim() === '' ||
      DriverName.trim() === '' ||
      DriverNo.trim() === '' ||
      CustomerName.trim() === '' ||
      CustomerNumber.trim() === '' ||
      // Remarks.trim() === '' ||
      Location.trim() === '' ||
      TimeDate.trim() === '' ||
      CurrentStatus.trim() === ''
    ) {
      toast.error('Please fill out all required fields', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeButton: false,
      });
      return;
    }

    // Check if LRNO already exists
    const isLRNOExists = Vehicle.some((v) => v.LR_no === LRNO);

    if (isLRNOExists) {
      toast.error('LRNO already exists!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeButton: false,
      });
      return;
    }

    const Data = {
      LRNO,
      DriverName,
      DriverNo,
      CustomerName,
      CustomerNumber,
      Remarks,
      Location,
      TimeDate,
      CurrentStatus,
    };

    // console.log("Data" , Data);

    axios
      .post(BaseUrl + '/api/insertData', Data)
      .then(function (response) {
        // console.log(response.data);

        toast.success('Vehicle added successfully!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
          closeButton: false,
        });

        navigate('/dashboard');
      })
      .catch((error) => {
        console.log(error); 
      });
  };

  const handleBack = () => {
    navigate("/dashboard");
  };

  return (
    <div className="container mt-5">
       
      <h2 className="text-center mb-4 addclass">Add Vehicle
      <button className='btn2' onClick={handleBack}>
      <TbArrowBackUpDouble  style={{ fontSize: '36px', color: '#184A45FF',backgroundColor:"transparent" }} />
    </button></h2>
      <form className="frm">
        <div className="mb-3">
          <label htmlFor="lrno" className="form-label">
            LRNO *
          </label>
          <input
            type="text"
            className="form-control"
            id="lrno"
            placeholder="Enter LRNO"
            name="LRNO"
            value={LRNO}
            onChange={(e) => setLRNO(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="driverName" className="form-label">
            Driver Name *
          </label>
          <input
            type="text"
            className="form-control"
            id="driverName"
            placeholder="Enter Driver Name"
            value={DriverName}
            name="DriverName"
            onChange={(e) => setDriverName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="driverNo" className="form-label">
            Driver Number *
          </label>
          <input
            type="text"
            className="form-control"
            id="driverNo"
            placeholder="Enter Driver No"
            name="DriverNo"
            onChange={(e) => setDriverNo(e.target.value)}
            value={DriverNo}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="customerName" className="form-label">
            Customer Name *
          </label>
          <input
            type="text"
            className="form-control"
            id="customerName"
            placeholder="Enter Customer Name"
            name="CustomerName"
            onChange={(e) => setCustomerName(e.target.value)}
            value={CustomerName}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="customerNumber" className="form-label">
Vehicle Number *         </label>
          <input
            type="text"
            className="form-control"
            id="customerNumber"
            placeholder="Enter Customer Number"
            name="CustomerNumber"
            onChange={(e) => setCustomerNumber(e.target.value)}
            value={CustomerNumber}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="remarks" className="form-label">
            Remarks
          </label>
          <input
            type="text"
            className="form-control"
            id="remarks"
            placeholder="Enter Remarks"
            name="Remarks"
            onChange={(e) => setRemarks(e.target.value)}
            value={Remarks}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="location" className="form-label">
            Location *
          </label>
          <input
            type="text"
            className="form-control"
            id="location"
            placeholder="Enter Location"
            name="Location"
            onChange={(e) => setLocation(e.target.value)}
            value={Location}
            required
          />
        </div>
        {/* <div className="mb-3">
          <label htmlFor="time" className="form-label">
            Time
          </label>
          <input
            type="text"
            className="form-control"
            id="time"
            placeholder="Enter Time"
            name="Time"
            onChange={(e) => setTime(e.target.value)}
            value={Time}
            required
          />
        </div> */}
       <div className="mb-3" style={{ marginBottom: '1rem', maxWidth: '300px' }}>
  <label htmlFor="date" className="form-label" style={{ display: 'block', marginBottom: '0.5rem' }}>
    Date and time *:
  </label>
  <input
    type="datetime-local"
    value={formatDate(TimeDate)}
    onChange={(e) => setTimeDate(e.target.value)}
    style={{ padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc' }}
  />
</div>

        <div className="mb-3">
          <label htmlFor="currentStatus" className="form-label">
            Current Status *
          </label>
          <select
  className="form-select"
  id="currentStatus"
  value={CurrentStatus}
  onChange={(e) => setCurrentStatus(e.target.value)}
  name="CurrentStatus"
  required
>
  <option value="" disabled>
    Select Current Status *
  </option>
  {Array.isArray(Statuss) && Statuss.map((status) => (
  <option key={status.id} value={status.current_status}>
    {status.current_status}
  </option>
))}
</select>

        </div>
        {EditValue ? (
          <button type="submit" className="btn1 btn-primary btn5" onClick={HandelUpdate}>
            Edit
          </button>
        ) : (
          <button type="submit" className="btn1 btn-primary btn5" onClick={HandelSubmit}>
            Add
          </button>
        )}
      </form>
    </div>
  );
}

export default Add;
