import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Dashboard/Dashboard.css";
import anim from "../../Assets/images/anim2.gif";
import { BaseUrl } from '../../Url';
import { useLocation } from 'react-router-dom';
import { TbArrowBackUpDouble } from 'react-icons/tb';
import { BsBoxArrowInLeft } from 'react-icons/bs';
import '../Admin/History.css';
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css'; // Import the styles
import set from 'date-fns/fp/set';


function History() {

 
  function formatDateTime(inputDate) {
    if (!inputDate) {
      return ''; // or handle the case where inputDate is undefined or null
    }
  
    const [datePart, timePart] = inputDate.split('T');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes] = timePart.slice(0, 5).split(':');
  
    // Add leading zeros if needed
    const formattedDay = day.padStart(2, '0');
    const formattedMonth = month.padStart(2, '0');
  
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12) || 12;
  
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}:${formattedHours}:${minutes} ${period}`;
  
    return formattedDate;
  }
  
  
  
  
  const [data, setData] = useState([]);
  const [showFinishConfirmation, setShowFinishConfirmation] = useState(false);
  const [selectedLRNO, setSelectedLRNO] = useState('');
  const [showold, setshowold] = useState(false);
  const [Date, setDate] = useState('');
  const [Date2, setDate2] = useState('');
  const [rangehistory, setrangehistory] = useState([]);
  const [rangehistoryupdate, setrangehistoryupdate] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [chosenDateRange, setChosenDateRange] = useState('Last 30 days history ');
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [EmptyShow, setEmptyShow] = useState(false)


  const navigate = useNavigate();

  const handleRefreshPage=()=>{
    window.location.reload();
  }

  const handleoldhistory = () => {
    setshowold(true);
  }

 
  const HandelDate = () => {
    const requestData = {
      Date,
      Date2,
    };
    setChosenDateRange(`${Date} to ${Date2}`);

    setshowold(false);

    axios.get(BaseUrl + "/oldhistory", { params: requestData })
      .then(function (response) {
        setData(response.data || []);
        // console.log("OLD", response.data || []);
       
      })
      .catch(function (err) {
        console.error("Error fetching old history:", err);
        if (err.response && err.response.status === 404) {
          setData("no data")
          setEmptyShow(true)
          // Display toast for "No data found"
          toast.error('No data found on the specific date', {
            position: toast.POSITION.TOP_CENTER
            

          });
        } else {
          // Handle other errors as needed
        }
      });
  };


  const parms = useLocation();

  const handleFinishConfirmation = (lrno) => {
    setSelectedLRNO(lrno);
    setShowFinishConfirmation(true);
  };

  const handleCloseFinishConfirmation = () => {
    setShowFinishConfirmation(false);
  };

  const handleConfirmFinish = () => {
    axios.post(BaseUrl + `/finish?lrno=${selectedLRNO}`).then(function (response) {
      RefreshDate();
    });
    handleCloseFinishConfirmation();
  };

  function formatDate(inputDate) {
    const [year, month, day] = inputDate.split("-");
    return `${year}-${month}-${day}`;
  }

  function formatDatee(inputDate) {
    const [year, month, day] = inputDate.split("-");
    return `${year}-${month}-${day}`;
  }

  const handelHistory = () => {
    navigate("/History");
  }

  useEffect(() => {
    const authData = localStorage.getItem('authData');
    if (!authData) {
      navigate('/admin');
    }
  }, [navigate]);

  const handleButton = () => {
    navigate("/dashboard");
  };

  const handleBack = () => {
    navigate("/dashboard");
  };

  const RefreshDate = () => {
    try {
      axios.get(BaseUrl + '/getvehicledata')
        .then(response => {
          setData(response.data);
        })
        .catch(error => {
          // Handle error
        })
        .then(() => {
          axios.get(BaseUrl + '/getvehicleupdatedatafinish')
            .then(response => {
              setUpdateData(response.data || []);
            })
            .catch(error => {
              // Handle error
            });
        });
    } catch (error) {
      // Handle error
    }
  }

  const handleLogout = () => {
    setShowLogoutModal(true);
  };
  const confirmLogout = () => {
    localStorage.removeItem('authData');
    setShowLogoutModal(false);
    navigate('/admin');
  };



  useEffect(() => {
    axios.get(BaseUrl + '/getvehicledatafinish')
      .then(response => {
        setData(response.data);
        // console.log("rayu",response.data);
      })
      .catch(error => {
        // Handle error
      });
  }, []);

  // useEffect(() => {
  //   axios.get(BaseUrl + '/getvehicleupdatedatafinish')
  //     .then(response => {
  //       setUpdateData(response.data || []);
  //     })
  //     .catch(error => {
  //       // Handle error
  //       setUpdateData([]);
  //     });
  // }, []);

  // const mergedData = data.map((vehicle) => {
  //   const update = Array.isArray(updateData) ? updateData.find((updateItem) => updateItem.LR_no === vehicle.LR_no) : null;

  //   if (update) {
  //     return {
  //       ...vehicle,
  //       Location: update.location || '',
  //       Time: update.time || '',
  //       Date: update.Date || '',
  //       CurrentStatus: update.current_status || '',
  //     };
  //   }

  //   return null;
  // }).filter(Boolean);

  // console.log("Merged Data:", mergedData);

  return (
    <div className="container">
      <div className="d-flex flex-column align-items-center vh-100">
        <div
          className="d-flex justify-content-end align-items-center mb-3"
          style={{ marginTop: '10px' }}
        >
          <BsBoxArrowInLeft
            style={{ fontSize: '34px', color: '#184A45FF', cursor: 'pointer'  }}
            className="logg"
            onClick={handleLogout}
          />
        </div>


        {
          EmptyShow ?(
            <div>
            <img src={anim} alt="babu boy" srcset="" className='img-fluid imgg' />
<div>            <button className="btn btn-success" onClick={handleRefreshPage}>
              Back To History
            </button></div>

            </div>

          ):""
        }

        {Array.isArray(data) && data.length === 0 && (
          <div className="text-center">
            <div className='noData'><img src={anim} alt="" className='img-fluid imgg' /></div>
            <p className="mb-3">No History to Show</p>
            <button className="btn btn-success" onClick={handleButton}>
              Back To Admin
            </button>
          </div>
        )}

        {Array.isArray(data) && data.length > 0 && (
          <div>
            <TbArrowBackUpDouble onClick={handleBack} style={{ fontSize: '36px', color: '#184A45FF', cursor: "pointer", marginTop: "10px" }} />


            <h1>Finished Loads</h1>
            <button onClick={handleoldhistory} className="btn btn-success">Choose Date</button>             <span className='choosed'>{`Choosen date is ${chosenDateRange}`}</span>


            <table className="table table-hover custom-table-new">
              <thead>
                <tr>
                  <th scope="col">Sl no</th>
                  <th scope="col">LRNO</th>
                  <th scope="col">Driver Name</th>
                  <th scope="col">Driver Number</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Vehicle Number</th>
                  <th scope="col">Remark</th>
                  <th scope="col">Location</th>
                  <th scope="col">DateTime</th>
                  {/* <th scope="col">Date</th> */}
                  <th scope="col">Current Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.LR_no}</td>
                    <td>{item.Driver_name}</td>
                    <td>{item.Driver_number}</td>
                    <td>{item.Customer_name}</td>
                    <td>{item.Vehicle_number}</td>
                    <td>{item.Remarks}</td>
                    <td>{item.location}</td>
                    <td>{formatDateTime(item.Date)}</td>
                    <td style={{ color: item.CurrentStatus === "Finished" ? "green" : "", fontWeight: item.CurrentStatus === "Finished" ? "bold" : "normal" }}>
                      {item.current_status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {showold && (
          <Modal show={showold} onHide={() => setshowold(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Choose Your Date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
              <div className="mb-3">
  <label htmlFor="from" className="form-label" style={{ display: 'block', marginBottom: '0.5rem' }}>
    From:
  </label>
  <input
    type="date"
    value={formatDate(Date)}
    onChange={(e) => setDate(e.target.value)}
    style={{
      padding: '0.5rem',
      borderRadius: '4px',
      border: '1px solid #ccc',
      width: '100%', // Adjust the width as needed
    }}
  />
</div>

<div className="mb-3">
  <label htmlFor="to" className="form-label" style={{ display: 'block', marginBottom: '0.5rem' }}>
    Upto:
  </label>
  <input
    type="date"
    value={formatDatee(Date2)}
    onChange={(e) => setDate2(e.target.value)}
    style={{
      padding: '0.5rem',
      borderRadius: '4px',
      border: '1px solid #ccc',
      width: '100%', // Adjust the width as needed
    }}
  />
</div>

              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setshowold(false)}>
                Cancel
              </Button>
              <Button onClick={HandelDate} variant="primary">
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
      <Modal show={showLogoutModal} onHide={() => setShowLogoutModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Logout Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to logout?
        </Modal.Body>
        <Modal.Footer>
          <Button className='logout' variant="primary" onClick={confirmLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showFinishConfirmation} onHide={handleCloseFinishConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to finish LRNO {selectedLRNO}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFinishConfirmation}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmFinish}>
            Finish
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default History;
