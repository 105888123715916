
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IoIosExit } from 'react-icons/io';
import { ToastContainer, toast } from 'react-toastify';

import Truck from '../../Assets/images/again3T-transformed.jpeg';
import "../User/User.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { BaseUrl } from '../../Url';
import Meter from './Meter';
import { FaSync } from 'react-icons/fa';
import { TbRefresh } from 'react-icons/tb';
import { TbArrowBackUpDouble } from 'react-icons/tb';
import { Button, Modal } from 'react-bootstrap';






function User() {
    const [LRNO, setLRNO] = useState('')
    const [Data, setData] = useState([])
    const [UserValue, setUserValue] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
      };

    const handelrefreshfunction =async()=>{
        console.log("clickesddd");
        try {
            const response = await axios.post(BaseUrl + "/api/view", { LRNO });
            setData(response.data);
           
            console.log("ViewW:" );
        } catch (error) {
            console.error(error);
        }
    }

    const [Show, setShow] = useState(false)
// console.log("ayk:" , Data);
const HandelShow = ()=>{
    setIsModalOpen(true)

    setShow(true)
    // console.log("Show clicked");

    // if(Data[0]){
    //     setShow(true)
    // }
}

    const parms = useLocation()
    const navigate = useNavigate();
    // console.log("usertable:",UserValue);
    useEffect(() => {
        if (parms.state) {
            setUserValue(parms.state.data)
            // console.log("usertable:",parms.state.data);
        }
      
    }, [])

    useEffect(() => {
        if (UserValue) {
            setLRNO(UserValue.LR_no)
        }
    }, [UserValue])

    const HandelLRNO = async (e) => {
        // console.log("refresh");
        e.preventDefault();

        try {
            const response = await axios.post(BaseUrl + "/api/view", { LRNO });
            setData(response.data);
            console.log("RealView",response.data);
            console.log("lrno",LRNO);
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 400) {
                // Show a toast if the status code is 400
                setShow(false)
                setIsModalOpen(false)
                toast.error('Please enter a valid LRNO', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                });
            }
        }
    };




    useEffect(() => {
        axios.get(BaseUrl + "/api/view", { params: { LRNO } }).then(function (response) {
            // console.log(response.data);
            setData(response.data);
            console.log("RealView",response.data);
            console.log("lrno",LRNO);
        }).catch((error) => {
            console.log(error);
            if (error.response && error.response.status === 400) {
                // Show a toast if the status code is 400
                toast.error('Please enter a valid LRNO', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                });
            }
        })
    }, [LRNO]);

    return (
        <div className='bb'>
            {/* Image Section */}
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12">
                        <img
                            src={Truck}
                            alt="Truck Image"
                            className='img-fluid truck'
                            style={{ width: '100%', maxHeight: '50vh' }}
                        />
                    </div>
                </div>
            </div>

            {/* Text Section */}
            <div className="container text-center mt-3">
                <div className="col-12">
                    <span className='spann' style={{ fontWeight: 'bold', fontSize: '2em' }}>
                        Royal
                    </span>
                    <span className='spannn' style={{ fontWeight: 'bold', fontSize: '2em' }}> Road Transport</span>
                </div>
            </div>

            {/* Form Section */}
            <div className="container text-center mt-3">
  <div className="row justify-content-center">
    <div className="col-12 col-md-6">
      <form className="input-container" onSubmit={HandelLRNO}>
        <div className="form-group d-flex flex-column flex-md-row align-items-center">
          <input
            type="text"
            className="form-control mb-2 mb-md-0 mr-md-2 input-field"
            id="lrno"
            name="lrno"
            placeholder="Enter LRNO"
            onChange={(e) => setLRNO(e.target.value)}
            value={LRNO}
          />
          <button
            type="submit"
            className="btn btn-primary col-12 col-md-2 input-button btn5"
            style={{ backgroundColor: '#007BFF' }}
            onClick={HandelShow}
          >
            Submit 
          </button>
        </div>
        {UserValue ? (
          <button
            className="btn btn-back input-button btn6"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <TbArrowBackUpDouble
              style={{
                fontSize: "36px",
                backgroundColor: "transparent",
                cursor: "pointer",
                transform: "scaleX(-1)",
              }}
            />
          </button>
        ) : ""}
      </form>

      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Customer Name: {Data[0]?.Customer_name}</Modal.Title> 
        
        </Modal.Header>
        <Modal.Body>
        <div className="container">
  <table className="table">
    <thead>
      <tr>
        <th>Vehicle no</th>
        <th>Driver no</th>
        <th>Driver name</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{Data[0]?.Vehicle_number}</td>
        <td>{Data[0]?.Driver_number}</td>
        <td>{Data[0]?.Driver_name}</td>
      </tr>
    </tbody>
  </table>
</div>

          
          {Data[0] ? <Meter Data={Data} /> : ""}
        </Modal.Body>
        
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handelrefreshfunction }>
            <FaSync style={{ marginRight: '5px' }} />
            Refresh
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    <div className='hello'></div>
  </div>
</div>


        </div>
    );
}

export default User;
