import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import '../Admin/Admin.css';
import axios from 'axios';
import { IoEye, IoEyeOff } from 'react-icons/io5'; // Import Eye icons

import bg from '../image/image1.jpg';

import { BaseUrl } from "../../Url"

function Admin() {
  const navigate = useNavigate();
  const [Username, setUsername] = useState('');
  const [Password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      Username,
      Password,
    };

    try {
      const response = await axios.post(BaseUrl + '/api/login', data);

      if (response.data) {
        setLoginError(false); // Reset login error state

        // Store data in localStorage
        localStorage.setItem('authData', JSON.stringify(response.data));

        navigate('/dashboard');
      } else {
        setLoginError(true);
        // console.log("Invalid credentials");
      }
    } catch (error) {
      console.error('Error during login:', error);
      // Handle other errors
    }
  };

  return (
    <div className="container-fluid text-center mt-5 home ">
      <h1 className="mb-4 admin">Royal Road Transports</h1>
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card admincard">
            <div className="card-header bg-primary text-white head ">
              <h2 className='adminlogin'>Admin Login</h2>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    name="Username"
                    placeholder="Enter your username"
                    value={Username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      id="password"
                      name="Password"
                      placeholder="Enter your password"
                      value={Password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btnEye btn-outline-secondary"
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <IoEyeOff /> : <IoEye />}
                      </button>
                    </div>
                  </div>
                </div>
                {loginError && (
                  <div className="alert alert-danger" role="alert">
                    Username or password is incorrect.
                  </div>
                )}
                <button type="submit" className="btn btn-primary btn-block btn5">
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
